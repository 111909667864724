.auto-complete-search {
  input[type='text'] {
    width: 100%;
  }
}

.sign-medication-modal-content {
  h3 {
    font-size: 18px;
    font-family: Mukta, sans-serif;
    font-weight: 600;
  }
}
