.bypass-button {
  height: 25px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}

.chat-prefilled {
  width: 100%;
  height: 10em;
}
