.day-notes-since-followup {
  border-radius: 5px;
  background-color: #ffe8ea;
  font-size: 18px;
  font-family: Mukta, sans-serif;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}
