.anamnesis-update-list {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    // display: none;
  }
}
