@import '../../../styles/colors.scss';

.chat-attachment {
  font-family: IsidoraSans-Bold;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #242423;
  width: 65%;
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 2rem;
  max-width: 400px;

  h3 {
    font-size: 18px;
    font-weight: bold;
  }

  .file-download {
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $accumboBrandMainColor;
    height: 50px;
  }
}

.file-info {
  border-radius: 5px 5px 0 0;
  padding: 0.75rem;
  background-color: #f2f2f2;
  border-bottom: 1px solid #d9d9d9;
}

.file-info__filename {
  font-family: IsidoraSans-SemiBold;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-info__timestamp {
  font-family: IsidoraSans-SemiBold;
  font-size: 12px;
  color: #656564;
  font-weight: normal;
}
