@import '../RowTypes.scss';

.row-container {
  &:last-child {
    .table-row {
      border-bottom: 0;
    }
  }

  .styled-checkbox + label:before {
    margin-right: 15px;
  }
}

.goal-value {
  margin-bottom: 10px !important;
}

.goals-edit-container {
  width: 120px;
}

.goal-edit-action {
  margin-left: 10px;
}

.goal-invalid {
  background-color: red;
}
