.member-event-row-wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    margin-bottom: 100px;
    border-bottom: none;
  }

  .snoozed-until {
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: 12px;
    color: #7f7579;
    padding: 3px;
    border-radius: 4px;

    &.animate {
      .icon {
        animation: ring 3s ease-in-out;

        @media (prefers-reduced-motion) {
          animation: none !important;
        }
      }
    }

    .icon {
      display: inline-block;
      width: 15px;
      height: 15px;
    }
  }
  .icons {
    &.checkup,
    &.yearly-checkup,
    &.three-month-checkup,
    &.nurse-checkup,
    &.nurse-reminder,
    &.reminder {
      padding: 18px 0;
      text-align: center;
      background-position-y: 5px;
    }
  }
}

.member-event-row {
  user-select: text;
  padding: 18px 0;
  margin-bottom: 0 !important;

  .dropdown-menu {
    left: 3px;
  }

  .event-type {
    font-family: Mukta, sans-serif;
    font-size: 18px;
    font-weight: 500;
    white-space: pre-line;
  }

  .event-timestamp,
  .event-handled-by {
    font-family: Mukta, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #7f7579;
  }

  .event-icon-wrapper {
    max-width: 55px;
  }

  a {
    font-weight: 500;
    border: none;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .dropdown-trigger {
    &.icon {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.event-done-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

@keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}
