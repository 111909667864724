@import '../../styles/breakpoints.scss';

.events-dropdown-overlay {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;

  &.hidden {
    display: none;
  }
}

.events-dropdown-container {
  @include for-laptop-up {
    right: calc(31% - 50px);
    top: 76px;
    width: 465px;
  }

  font-family: IsidoraSans-SemiBold, sans-serif;
  width: 85%;
  padding: 21px;
  position: fixed;
  background-color: #ffffff;
  border: solid 1px #dcd2d2;
  box-shadow: 0 0 0 4px rgba(62, 23, 23, 0.05);
  border-radius: 10px;
  z-index: 5;
  transition: top 0.2s ease;

  &.hidden {
    display: none;
    pointer-events: none;
    top: 56px;
  }

  h4 {
    font-family: IsidoraSans-Bold;
    font-size: 14px;
  }

  .handled-events {
    margin-top: 30px;
    max-height: 250px;
    overflow-y: auto;
  }

  .active-events {
    max-height: 500px;
    overflow-y: auto;
  }

  .no-events {
    font-size: 14px;
  }
}

.icon-checkmark-container {
  display: inline;
  position: relative;
}
