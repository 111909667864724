@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';

.main-menu {
  --transition-speed: 0.2s;
  display: none;
  width: 70px;
  position: fixed;
  background-color: $accumboBrandMainColor;
  transition: width var(--transition-speed) ease;
  z-index: 2;

  @include for-laptop-up {
    display: block;
    height: 100vh;
    padding-top: 20px;
    padding-left: 20px;
  }

  @media (prefers-reduced-motion) {
    transition: none !important;
  }

  &:not(.menu-collapsed):hover {
    width: 150px;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.3);

    .menu-links a span {
      opacity: 1;
    }
  }

  li {
    margin-bottom: 50px;
  }

  .logo {
    margin-bottom: 100px;
  }

  .menu-links a {
    position: relative;
    display: block;
    height: 34px;
    overflow: hidden;
    padding-left: 38px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #ffffff;
    font-family: 'Mukta', sans-serif;

    &:hover::before {
      opacity: 0.8;
    }

    &::before {
      opacity: 0.4;
      background-repeat: no-repeat;
      background-position: center;
      background-clip: border-box;
      content: '';
      width: 32px;
      height: 34px;
      display: inline-block;
      position: absolute;
      left: 0;
    }

    & span {
      transition: opacity var(--transition-speed) ease;
      opacity: 0;

      @media (prefers-reduced-motion) {
        transition: none !important;
      }
    }
  }

  .menu-link--inbox::before {
    background-image: url('../../assets/navigation-icon-errends.svg');
  }

  .menu-link--patient-list::before {
    background-image: url('../../assets/navigation-icon-all-patients.svg');
  }

  .menu-link--referrals::before {
    background-image: url('../../assets/navigation-icon-referrals.svg');
  }

  .menu-link--inactive::before {
    background-image: url('../../assets/navigation-icon-inactive.svg');
  }

  .menu-link--selected::before {
    opacity: 1 !important;
  }

  .lower-menu {
    position: absolute;
    bottom: 20px;
    left: 11px;

    .menu-toggler {
      font-size: 40px;
      font-family: 'IsidoraSans-SemiBold', sans-serif;
      color: #ffffff;
      text-align: center;
      user-select: none;
    }

    .menu-item {
      padding: 0 10px 5px 10px;
    }

    strong {
      font-size: 18px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: #656564;
    }

    a,
    .menu-item > span {
      padding-top: 0.6em;
      font-size: 16px;
      height: 38px;
      display: block;
      color: #242423;
      font-weight: bold;

      &:hover {
        color: $accumboBrandMainColor;
      }
    }
  }
  .dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    height: 46px;
    width: 46px;

    img {
      border-radius: 50%;
      border: 2px solid #ffffff;
    }

    div {
      background-color: #d9d9d9;
      font-family: Mukta, sans-serif;
      font-size: 15px;
      font-weight: bold;
    }
  }

  .dropdown-content {
    box-shadow: 0 2px 4px 0 rgba(48, 12, 12, 0.2);
    padding: 16px 10px;
  }
}

.sidebar-notification-container {
  pointer-events: none;
  position: relative;
  top: 10px;
  right: -17px;
  z-index: 1;
  min-height: 24px;
}

.sidebar-notification {
  text-align: center;
  display: inline-block;
  padding: 0 5px;
  line-height: 1.4;
  height: 16px;
  background-color: #ff223b;
  color: #ffffff;
  font-family: Mukta;
  font-size: 12px;
  font-weight: bold;
  border-radius: 8px;
}

.sidebar__brand-logo:hover {
  animation: heartbeat 1s ease-in-out infinite;

  @media (prefers-reduced-motion) {
    animation: none !important;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
