.measurement-period {
  font-family: Mukta, sans-serif;
  font-size: 18px;
  color: #242423;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #ccc4c2;
  padding: 10px 15px;
  margin-right: 14px;

  &:last-child {
    margin-right: 0;
  }

  h1 {
    font-size: 18px;
    font-weight: 500;
    color: #242423;
    margin-bottom: 20px;
  }

  h2 {
    opacity: 0.8;
    font-family: Mukta;
    font-size: 15px;
    font-weight: 500;
    color: #50494d;
    margin-bottom: 5px;

    & + div {
      margin-bottom: 15px;
    }
  }
}
