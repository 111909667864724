.risk-factor-treatment {
  font-family: Mukta, sans-serif;
  font-size: 18px;
  color: #242423;
  border-radius: 5px;
  background-color: #f0f0f0;
  padding: 10px 0;
  margin-bottom: 30px;

  .styled-checkbox {
    display: none;
  }

  &.risk-factor-type-standard {
    &.selected {
      background-color: #defdd7;
    }
  }

  &.risk-factor-type-non-standard {
    background-color: #fdf0d7;
    &.selected {
      background-color: #defdd7;
    }
  }

  .styled-checkbox:checked + label:after {
    top: 7px;
  }
}
