@import '../../../styles/colors.scss';

:root {
  --transitionSpeed: 200ms;
}

.canned-responses {
  position: relative;

  span {
    cursor: pointer;
  }

  .dropdown-trigger {
    .button {
      padding-left: 5px;
    }
  }

  .popover {
    background-color: #ffffff;
    position: absolute;
    bottom: 50px;
    width: 300px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(48, 12, 12, 0.2);
    font-size: 16px;
  }

  .popover-menu {
    width: 100%;
    padding: 10px;
  }

  .menu-item {
    height: auto;
    display: flex;
    align-items: center;
    padding: 10px;
    color: black;

    cursor: pointer;
    font-family: 'Mukta', sans-serif;
    border-radius: 3px;
    margin-bottom: 5px;
  }

  .menu-item:hover {
    background-color: $dropdownHoverColor;
  }

  .menu-item__category {
    color: $accumboBrandMainColor;
  }

  .menu-item__back {
    color: $accumboBrandMainColor;
    font-weight: bold;

    &:after {
      content: '';
      display: inline-block;
      position: relative;
      top: -2px;
      width: 12px;
      height: 8px;
      background-image: url('../../../assets/icons/chevron.svg');
      background-repeat: no-repeat;
      margin-left: 5px;
    }
  }

  .menu-primary-enter {
    position: absolute;
    transform: translateY(110%);
  }

  .menu-primary-enter-active {
    transform: translateY(0);
    transition: transform var(--transitionSpeed) ease;
  }

  .menu-primary-exit-active {
    display: none;
  }

  .menu-secondary-enter {
    transform: translateY(110%);
  }

  .menu-secondary-enter-active {
    transform: translateY(0%);
    transition: transform var(--transitionSpeed) ease;
  }

  .menu-secondary-enter-done {
    max-height: 85vh;
    overflow-y: auto;
  }

  .menu-secondary-exit-active {
    display: none;
  }
}
