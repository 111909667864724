.global-banner {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(216, 56, 74, 0.8);
  color: #ffffff;
  font-family: Mukta, sans-serif;
  padding: 20px 10px;
  z-index: 2;
}

.banner-margin {
  height: 65px;
}
