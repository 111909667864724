.row-category {
  color: #50494d;
}

.row-response {
  color: #242423;
}

.row-container {
  position: relative;

  &.has-updated-value {
    border-left: 5px solid #ffac59;

    .column:first-child {
      padding-left: 7px;
    }
  }
}

.row-history-overlay {
  position: fixed;
  top: 73px;
  left: 70px;
  width: 100%;
  height: 100%;
  z-index: 1;

  &.hidden {
    display: none;
  }
}

.table-row {
  font-size: 18px;
  line-height: 1.33;
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 0 !important;

  .open-history {
    height: 25px;
  }

  .row-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 25px;
  }
}

.edit-row,
.open-history {
  margin-left: 5px !important;
  height: 25px;
}
