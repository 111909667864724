.medication-search-result-selection {
  color: #242423;
  h2 {
    font-family: Mukta, sans-serif;
    font-weight: 500;
  }

  small {
    font-size: 16px;
    color: #656564;
  }

  .is-1 {
    width: 3.333%;
  }

  .is-4 {
    color: #7f7579;
  }

  .vara-selection {
    padding: 20px;
    border-radius: 5px;
    border: solid 1px #cfcfcf;
  }

  .medication-original {
    padding: 20px;
    border-radius: 5px;
    background-color: #f2f2f2;
  }
}
