.dss-section {
  position: relative;
  font-family: Mukta, sans-serif;
}

.section-content {
  position: relative;
}

.section-loader {
  width: 30px;
  height: 30px;
  margin: auto;
  top: -20px;
  bottom: 0;
  right: 0;
  left: 0;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.section-note {
  position: absolute;
  top: 29px;
  right: 120px;
}

.section-header {
  display: inline-block;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  font-size: 20px;

  &.expanded {
    margin-bottom: 1.4em;
  }
}

.section-header-info {
  display: inline-block;
  color: #50494d;
  opacity: 0.8;
  font-size: 16px;
  margin-left: 1em;
  font-weight: normal;
}
