.notes-container {
  &:first-child {
    margin-right: 20px;
  }
}

.sub-tab-selector {
  display: inline-block;
  font-family: 'IsidoraSans-Bold', sans-serif;
  font-size: 16px;
  color: #282827;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(62, 23, 23, 0.05);
  border: solid 1px rgba(81, 30, 30, 0.2);
  background-color: #ffffff;
  padding: 0 20px;

  a {
    margin-right: 30px;
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 13px;
    color: #282827;

    &.is-active {
      color: #720034;
      border-bottom: 2px solid #720034;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
