.preferred-medication-note {
  color: #27b61e;
  font-size: 16px;
  margin-left: 35px;
  margin-bottom: 10px;

  .icons {
    width: 23px;
    height: 23px;
  }
}

.medication-options {
  overflow-y: auto;
  max-height: 51vh;
  min-height: 30px;
}

.vara-medication-option {
  margin-bottom: 20px;
  font-size: 14px;

  &.preferred {
    background-color: #f2f2f2;
    padding: 5px;
    border-radius: 5px;
  }
}

.medication-properties {
  color: #656564;
}

.medication-options-wrapper {
  max-height: 100%;
  overflow-y: hidden;
}
