@import '../RowTypes.scss';

.checkup-row.row-container {
  &:nth-last-child(2) {
    .table-row {
      border-bottom: 0;
    }
  }
}

.edit-checkup-container {
  height: 285px;
}

.checkup-reasons-icon {
  position: relative;
  margin-top: -20px;
  top: 8px;
}
