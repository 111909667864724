@import '../../styles/breakpoints.scss';

.login-container {
  font-family: 'Mukta';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  ::placeholder {
    color: #858585;
    opacity: 1;
    font-size: 15px;
  }

  h1 {
    margin-bottom: 20px;
    font-size: 16px;
    color: #242423;
  }

  .login-form {
    @include for-laptop-up {
      padding-top: 145px;
      max-width: 530px;
      width: 50%;
      height: 424px;
      background-position: center 70px;
    }

    padding-top: 110px;
    background-image: url('../../assets/blodtrycksdoktorn-logo.svg');
    background-repeat: no-repeat;
    background-position: center 40px;
    background-color: #ffffff;
    text-align: center;
    width: 90%;
    height: 350px;
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(62, 23, 23, 0.05);
    border: solid 1px rgba(81, 30, 30, 0.15);
  }

  button[type='submit'] {
    border-radius: 27px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 95%;
    max-width: 320px;
    height: 55px;
    margin-bottom: 10px;
    outline: none;
  }

  input[type='checkbox'] {
    margin-top: 40px;
    margin-left: 15px;
  }

  button[type='submit'] {
    display: block;
    margin: 0 auto;
    background-color: #3c90b6;
    background-image: url('../../assets/bankid-white-small-icon.svg');
    background-repeat: no-repeat;
    background-position: 25px;
    padding-left: 62px;
    padding-right: 50px;
    border: none;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }

  img {
    margin-right: 22px;
  }

  .build-number {
    font-size: 14px;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}
