@import '../../styles/breakpoints.scss';

.patient-info-container {
  padding: 20px 27px 20px 20px;
  box-shadow: inset 0px 2px 2px -2px rgba(0, 0, 0, 0.35);
}

.patient-chat-container {
  background-color: #eee8e8;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
  transition: right 0.2s ease-in, box-shadow 0.2s ease-in;
  z-index: 3;

  &.dragged {
    box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.2);
  }

  @include for-laptop-up {
    position: fixed;
    right: 0;
    top: 0;
    width: 31%;
  }
}

.alert-container {
  font-family: Mukta, sans-serif;
  box-shadow: 0 2px 0 0 rgba(62, 23, 23, 0.05);
  background-color: #d8384a;
  border: solid 1px #ba303f;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding: 5px;
  padding-left: 15px;
  margin-bottom: 24px;
}

.alert-content {
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    display: inline-block;
    content: '!';
    width: 24px;
    height: 24px;
    background-color: #efb300;
    border-radius: 12px;
    text-align: center;
    margin-right: 10px;
  }

  &.blocked {
    &:before {
      background-color: #ff0000;
    }
  }
}

.tab-column {
  overflow-x: auto;
  overflow-y: hidden;
}

@media (min-resolution: 1.26dppx) and (max-width: 1920px) {
  .tab-column {
    font-size: 14px;
  }

  .chat-header {
    padding-bottom: 6px;
  }
}

.patient-comments {
  position: fixed;
  padding: 10px;
  bottom: -365px;
  left: 80px;
  box-shadow: 0 6px 5px 0 rgba(165, 165, 165, 0.795);
  background-color: rgb(250, 250, 250);
  border: solid 1px rgba(81, 30, 30, 0.2);
  border-radius: 5px 5px 0 0;
  transition: bottom 0.2s ease;

  &.open {
    bottom: 0;
  }

  textarea {
    width: 300px;
    height: 300px;
    resize: none;
  }

  .comments-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-family: Mukta, sans-serif;
    font-weight: bold;
    padding-bottom: 15px;
  }
}
