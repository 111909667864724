.inline-comment {
  padding: 15px;
  margin-top: 15px;
  border-radius: 5px;
  background-color: #fff7cc;
  color: #242423;
  word-break: break-word;
}

.comment-meta {
  font-family: Mukta;
  font-size: 14px;
  font-weight: 600;
  color: #403000;
  margin-bottom: 10px;
}
