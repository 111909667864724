.mt-0 {
  margin-top: 0px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.ml-0 {
  margin-left: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.ml-15 {
  margin-left: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.ml-20 {
  margin-left: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.ml-25 {
  margin-left: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.ml-30 {
  margin-left: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}
.mr-35 {
  margin-right: 35px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.ml-35 {
  margin-left: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.ml-40 {
  margin-left: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.ml-50 {
  margin-left: 50px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.pt-0 {
  padding-top: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pl-0 {
  padding-left: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pl-10 {
  padding-left: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pl-15 {
  padding-left: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pl-20 {
  padding-left: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pl-30 {
  padding-left: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}
.pr-35 {
  padding-right: 35px !important;
}
.pb-35 {
  padding-bottom: 35px !important;
}
.pl-35 {
  padding-left: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pl-40 {
  padding-left: 40px !important;
}

.mt-0 {
  margin-top: 0px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.ml-0 {
  margin-left: 0px !important;
}

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}

.pos-rel {
  position: relative !important;
}

.pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.cursor-help {
  cursor: help !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-w-resize {
  cursor: w-resize;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.m-0-auto {
  margin: 0 auto;
}

.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}
.opacity-4 {
  opacity: 0.4 !important;
}
.opacity-5 {
  opacity: 0.5 !important;
}
.opacity-6 {
  opacity: 0.6 !important;
}
.opacity-7 {
  opacity: 0.7 !important;
}
.opacity-8 {
  opacity: 0.8 !important;
}

.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}
.fw-input {
  input[type='text'],
  textarea {
    width: 100%;
  }
}
.mw-900 {
  max-width: 900px !important;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
.strike {
  text-decoration: line-through;
}
.block {
  display: block;
}
.z99 {
  z-index: 99;
}
.fl-end {
  justify-content: flex-end;
}
.i {
  font-style: italic;
}
.fw-bold {
  font-weight: bold;
}
.fw-normal {
  font-weight: normal;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.pe-none {
  pointer-events: none;
}
