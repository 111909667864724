$accumboBrandMainColor: rgb(114, 5, 58);
$accumboBrandMainColorActive: lighten($accumboBrandMainColor, 5%);
$accumboBrandMainColorDisabled: #e0e0e0;
$accumboCaregiverChatBackground: #fee4ed;
$accumboNurseChatTextColor: rgb(79, 5, 114);
$accumboNurseChatBackground: rgb(237, 228, 254);
$accumboDieticianChatTextColor: rgb(49, 78, 0);
$accumboDieticianChatBackground: rgb(228, 254, 229);
$dropdownHoverColor: rgba(254, 228, 237, 0.5);
$rowColor: #ffffff;
$rowHoverColor: rgba($rowColor, 0.8);
$externalButtonBackgroundColor: #0095b3;
$externalButtonBackgroundColorActive: lighten($externalButtonBackgroundColor, 2%);
$externalButtonBorderColor: #007991;
$medicationHoverColor: #fcfcfc;
$medicationPreviousBackgroundColor: #f5f5f5;
$medicationUnsignedBackgroundColor: #ffefef;
$severityExtraLow: red;
$severityVeryLow: red;
$severityLow: red;
$severitySlightlyLow: orange;
$severityDecreased: orange;
$severityNormal: rgb(38, 198, 55);
$severityIncreased: orange;
$severitySlightlyHigh: orange;
$severityHigh: red;
$severityVeryHigh: red;
$severityExtraHigh: red;
$secondary: #0a7300;
$secondaryHover: lighten($secondary, 5%);
$secondaryActive: lighten($secondary, 7%);
$secondaryDisabled: lighten($secondary, 12%);
$tertiary: #3060bf;
$tertiaryHover: lighten($tertiary, 5%);
$tertiaryActive: lighten($tertiary, 7%);
$tertiaryDisabled: lighten($tertiary, 12%);
$successTextColor: #1f9918;
$secondaryCaregiverBackgroundColor: rgba(255, 105, 180, 0.1);
$euroAccident: #c6007e;
$minDoktor: #e52427;
$status-created-background: rgb(255, 249, 228);
$status-pending-background: rgb(255, 249, 228);
$status-exemption-background: rgb(234, 242, 238);
$status-complete-background: rgb(234, 242, 238);
$status-failed-background: rgb(255, 240, 241);
$status-created-color: rgb(240, 163, 32);
$status-pending-color: rgb(240, 163, 32);
$status-exemption-color: rgb(51, 128, 89);
$status-complete-color: rgb(51, 128, 89);
$status-failed-color: rgb(218, 30, 40);
