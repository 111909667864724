@import '../RowTypes.scss';

.simple-row.row-container {
  &:nth-last-child(2) {
    .table-row {
      border-bottom: 0;
    }
  }
}

.simple-history-container {
  .simple-history-row {
    border-bottom: 1px solid #e4e4e4;
    font-size: 18px;
    padding: 20px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
