.measurement-trends-current-period {
  text-align: center;
  font-family: Mukta, sans-serif;
  font-size: 18px;
  color: #242423;

  .current-period-header {
    text-align: center;
    opacity: 0.8;
    font-size: 15px;
    color: #50494d;
  }
}

.measurement-trends-control-period-size {
  font-family: Mukta, sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  height: 45px;
  width: 120px;
  border-style: solid;
  border-width: 2px;
  border-color: #dadada;
  color: #656564;

  span {
    height: 100%;
  }

  &.selected {
    border-color: #720034;
    color: #720034;
    pointer-events: none;
  }

  &.middle {
    border-radius: 0;
  }

  &.left {
    border-radius: 5px 0 0 5px;
  }

  &.right {
    border-radius: 0 5px 5px 0;
  }
}
