@import '../../styles/colors.scss';

.toggle-switch {
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 38px;
    height: 23px;
    background: #d8d8d8;
    display: inline-block;
    border-radius: 23px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 19px;
      height: 19px;
      background: #ffffff;
      border-radius: 19px;
      transition: 0.3s;
    }
  }

  input:checked + label {
    background: $accumboBrandMainColor;
  }

  input:checked + label:after {
    transform: translateX(calc(100% - 4px));
  }
}
