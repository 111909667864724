@import '../../../styles/colors.scss';

.historical-note {
  font-family: Mukta, sans-serif;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
  padding-left: 3px;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  .note-author {
    padding: 2px 3px;
    margin-left: 5px;
    border-radius: 4px;
    font-size: 0.9em;
  }

  .note-author--nurse {
    color: $accumboNurseChatTextColor;
    background-color: $accumboNurseChatBackground;
  }

  .note-author--doctor {
    color: $accumboBrandMainColor;
    background-color: $accumboCaregiverChatBackground;
  }

  time {
    font-size: 14px;
    color: #7f7579;
  }
}

.historical-note-footer {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}
