.measurement-history-table {
  font-family: Mukta, sans-serif;
  font-size: 18px;
  color: #242423;
  border-radius: 5px;
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 45px;

  .column-headers {
    font-family: IsidoraSans-SemiBold, sans-serif;
    font-size: 15px;
    color: #242423;
    border-radius: 5px 5px 0 0;
    background-color: #d4d4d4;

    div {
      padding-top: 5px;
      padding-bottom: 7px;
    }
  }
}

.measurement-history-row {
  border-bottom: 1px solid #d4d4d4;

  &:last-child {
    border-bottom: none;
  }
}

.no-border-bottom {
  border-bottom: none;
}

.show-historical-measurements,
.hide-historical-measurements {
  margin-top: 5px;

  a {
    font-size: 16px;
    font-weight: bold;
    color: #720034;
  }
}
