.values-container {
  max-height: 20vh;
  overflow-y: auto;
  overscroll-behavior: contain;

  .lab-result-value {
    padding: 5px 0;
  }
}

.row-comment-container {
  position: relative;
  bottom: -30px;
  left: -30px;
  width: calc(100% + 60px);
  background-color: #f1f1f1;
  border-top: 1px solid #d8d8d8;
  padding: 20px 30px 0 30px;

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: #656564;
  }

  textarea {
    width: 100%;
    height: 130px;
  }
}

.checkbox-column {
  flex: none;
  width: 0;
}

.row-edit-container {
  .styled-checkbox + label {
    top: -23px;
  }
}
