.medication-modal-content {
  input[type='text'],
  textarea {
    width: 100%;
  }

  .styled-checkbox {
    &:checked + label:after {
      top: 7px;
    }
  }

  h3 {
    margin-bottom: 15px;
  }
}
