@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';

.errands-container,
.new-members-container {
  padding: 20px;
  max-width: 1100px;
  margin: 0 auto;

  @include for-desktop-up {
    max-width: 1200px;
  }

  h2 {
    margin-bottom: 25px;
  }

  strong {
    font-family: 'IsidoraSans-SemiBold';
  }

  .sort-control {
    color: #656564;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    padding-right: 0;
    padding-top: 7px;

    em {
      font-style: normal;
      font-size: 14px;
      font-weight: 400;
      text-align: right;
      color: #656564;
    }

    .dropdown {
      display: block;
      margin-left: 12em;
      text-align: left;

      .dropdown-menu {
        min-width: 13rem;
      }

      .is-active {
        color: $accumboBrandMainColor;
        background-image: url('../../assets/icons/sorted-by-checkmark.svg');
        background-position: 93%;
        background-repeat: no-repeat;
      }
    }

    .dropdown-content {
      padding: 10px 5px;
    }

    .dropdown-item {
      font-size: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: $dropdownHoverColor;
      }
    }
  }

  .sort-control__changer {
    font-family: 'IsidoraSans-Bold';
    color: #343434;
    cursor: pointer;
  }

  a,
  .member-row {
    color: #242423;
    cursor: pointer;

    .errand-item {
      @include for-laptop-up {
        min-height: 70px;
        padding: 0 20px 0 30px;
        font-size: 16px;
      }

      @include for-phone-only {
        .column {
          padding: 5px !important;
        }
      }

      padding: 0 10px 0 15px;
      border-radius: 5px;
      box-shadow: 0 2px 0 0 rgba(62, 23, 23, 0.05);
      background-color: $rowColor;
      border: solid 1px rgba(81, 30, 30, 0.2);
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      &:hover {
        background-color: $rowHoverColor;
      }

      & > .column {
        padding: 15px 0;
      }

      .errand-icons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &.secondary-caregiver {
        background-color: $secondaryCaregiverBackgroundColor;
      }
    }

    &.new-errands {
      font-weight: bold;

      .errand-item {
        border-left: 4px solid #ffac59;
      }
    }

    &.new-errands {
      font-weight: bold;

      .errand-item {
        border-left: 4px solid #ffac59;
      }
    }
  }

  .errand-item__wait-time {
    font-family: 'Mukta';
    color: #242423;
  }

  .errand-headings {
    display: none;

    @include for-laptop-up {
      display: flex;
      font-size: 12px;
      font-family: 'IsidoraSans-Bold';
      text-transform: uppercase;
      color: #a69c9c;
      padding: 0 20px 0 30px;

      .column {
        padding: 0;
      }
    }
  }

  h2 {
    font-size: 22px;
  }
}

.new-members-row-container {
  position: relative;
  margin-bottom: 50px;
  z-index: 0;
}

.errand-row-container {
  position: relative;
  margin-bottom: 100px;
  z-index: 0;
}

.hide-missing-members {
  .styled-checkbox {
    & + label {
      font-size: 15px;

      span {
        position: relative;
        top: -2px;
      }
    }

    & + label:before {
      margin-right: 5px;
    }

    &:checked + label:after {
      left: 3px;
      top: 2px;
    }
  }
}

.event-list-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1em;

  input[type='checkbox'] + label {
    font-size: 15px;
    margin-left: 3px;
  }
}

.unassigned-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #282827;
  font-size: 18px;
  font-weight: 500;
  padding: 13px 23px 13px 30px;
  margin-bottom: 50px;

  .unassigned-content {
    position: relative;
    top: -6px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.num-unassigned {
  font-family: 'IsidoraSans-Bold', sans-serif;
  font-size: 2em;
  margin-right: 8px;
}
