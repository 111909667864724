.caregiver-prio {
  &.animations-enabled {
    .errand-label {
      background-image: linear-gradient(90deg, #0000 33%, rgba(255, 255, 255, 0.3) 50%, #0000 66%);
      background-size: 300% 100%;
      animation: shine 3s infinite;
    }
  }

  h1 {
    display: inline-block;
    font-family: 'IsidoraSans-Bold', sans-serif;
    font-size: 22px;
    color: #343434;

    .list-count {
      font-family: 'IsidoraSans-SemiBold', sans-serif;
      color: #7f7579;
    }
  }

  a.full-width-link {
    display: block;
    text-align: center;
    font-family: Mukta, sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: rgb(114, 0, 52);
    border-radius: 6px;
    background-color: hsl(339, 93%, 95%);
    padding: 10px 0;
    margin-top: 10px;

    &:hover {
      background-color: hsl(339, 93%, 96%);
    }
  }

  .priority-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    font-family: Mukta, sans-serif;

    &:hover {
      background-color: #fcfcfc;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #dddddd;
    }

    .member-name {
      font-family: 'IsidoraSans-SemiBold', sans-serif;
      & + span {
        font-family: Mukta, sans-serif;
        font-size: 16px;
        color: #808080;
        margin-left: 6px;
      }
    }

    .unread {
      .member-name {
        font-family: 'IsidoraSans-Bold', sans-serif;
      }
    }

    .additional-labels-count {
      font-family: Mukta;
      font-size: 14px;
      font-weight: 600;
      color: #808080;
      padding: 1px 7px;
      border-radius: 5px;
      border: solid 1px #979797;
      margin-left: 4px;
    }

    .errand-label {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 2px 7px;
      border-radius: 5px;
      background-color: #aaa;
      transition: transform 1s ease;
    }

    .icon {
      width: 20px;
      height: 20px;
    }

    .slow-onboarding,
    .followup-missing,
    .patient-not-ready,
    .symptom {
      background-color: #d12b64;
    }

    .titration-incomplete {
      background-color: #ff6824;
    }

    .new-message {
      background-color: #3e5fcb;
    }

    .low-bloodpressure,
    .high-bloodpressure {
      background-color: #d8384a;
    }

    .three-month-checkup,
    .yearly-checkup,
    .six-week-checkup,
    .extra-checkup,
    .checkup,
    .new-member-checkup,
    .nurse-checkup,
    .nurse-new-member,
    .nurse-two-week,
    .nurse-six-month,
    .nurse-yearly,
    .nurse-extra {
      background-color: #0aad00;
    }

    .titration-complete {
      background-color: #00ad91;
    }

    .standing {
      background-color: #7f24ff;
    }
  }
}

@media (prefers-reduced-motion) {
  .errand-label {
    animation: none !important;
  }
}
