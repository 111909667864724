.issue-comment {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .issue-comment__meta {
    font-size: 14px;
    text-align: right;
  }
}
