.revision {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 30px;
  margin-bottom: 30px;

  h3 {
    font-family: Mukta, sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .revision-property {
    margin-bottom: 10px;
  }
}
