.skipped-question {
  color: #656564;
}

.answer-highlight {
  &:before {
    content: '▶';
    padding-right: 5px;
  }
}

.question-alternative {
  font-weight: normal;
  font-size: 14px;
}
