.loading-spinner {
  display: inline-block;
  width: 59px;
  min-width: 59px;
  height: 59px;
  min-height: 59px;
  background-image: url('../../assets/accumbo-spinner.png');
  background-size: contain;
  animation: spin 1.5s linear infinite;

  &.small {
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
  }

  &.smaller {
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
