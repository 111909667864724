.lab-results-data-table-container {
  font-family: Mukta, sans-serif;

  .styled-checkbox {
    & + label {
      font-size: 15px;

      span {
        position: relative;
        top: -2px;
      }
    }

    & + label:before {
      margin-right: 5px;
    }

    &:checked + label:after {
      left: 3px;
      top: 2px;
    }
  }
}

.lab-result-cell {
  display: flex;
  align-items: center;

  sup {
    position: relative;
    top: -5px;
  }
}

.lab-result-header {
  text-align: left;
  font-family: 'IsidoraSans-SemiBold', sans-serif;
}

.ReactTable {
  border-radius: 5px;
  border: solid 1px #d4d4d4 !important;

  .rt-thead {
    box-shadow: none !important;
    border-bottom: 1px solid #d4d4d4;
    background-color: #d4d4d4;
  }

  .rt-thead,
  .rt-th {
    border-right: 0 !important;
  }
}

.lab-comment {
  font-size: 18px;
  cursor: help;
  color: red;
}
