.medication-view {
  h2 {
    font-family: Mukta, sans-serif;
  }

  .vara-bypass__reason-other {
    margin-top: 5px;
    textarea {
      width: 25%;
    }
  }
}
