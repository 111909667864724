.medication-search-results {
  min-height: 380px;
  max-height: calc(45vh - 70px);
  overflow-y: auto;

  .loading {
    opacity: 0.6;
  }

  &.collapsed {
    min-height: auto;
    max-height: 30%;
  }

  .result-row {
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 0;
    border-bottom: 1px solid #e1e1e1;

    &:last-child {
      border-bottom: 0;
    }

    padding: 10px 0;

    &:hover {
      background-color: #f8f8f8;
    }
  }
}

.medication-search-results__empty {
  justify-content: center;
  font-size: 18px;
  width: 500px;
  margin: 0 auto;
  height: 200px;
  color: #242423;
}
