.member-events {
  position: relative;
  border-left: 1px solid #eae2e2;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  height: 100%;
  margin-bottom: 0 !important;
}

.events-header {
  font-family: IsidoraSans-Bold, sans-serif;
  position: relative;
  top: 5px;
  font-size: 12px;
  color: #666666;
  margin-bottom: 11px;
}

.event-icons-wrapper {
  max-width: 190px;
  overflow-x: hidden;
  font-size: 12px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
}

.event-count {
  margin-left: 3px;
}

.open-indicator {
  transition: 200ms ease;
}
