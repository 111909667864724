.row-history-container {
  color: #242423;
  max-height: 70vh;
  overflow-y: auto;

  .history-row {
    border-bottom: 1px solid #e4e4e4;
    font-size: 18px;
    padding-bottom: 20px;

    a {
      color: #242423;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &:first-child {
      .history-timestamp {
        margin-top: 0;
      }
    }
  }

  .edit-member {
    flex: none;
    max-width: 230px;
  }

  .edit-date {
    flex: none;
    width: 85px;
    margin-left: 10px;
  }

  .history-timestamp {
    font-size: 18px;
    color: #656564;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .history-row-collapsed {
    display: none;
  }
}

.response-modification {
  padding: 10px;
  font-family: Mukta, sans-serif;
  font-size: 18px;
  border-radius: 5px;
  background-color: #ebebeb;
  color: #656564;
  width: 100%;

  &.response-removed {
    background-color: #fae9ee;
    color: #ed1c24;
  }

  &.response-added {
    background-color: #d0f6d1;
    color: #1f9918;
  }

  &.response-modified {
    background-color: #ffefe9;
    color: #ff6824;
  }
}

.collapse-items {
  > div {
    display: none;

    &:first-child {
      display: block;
    }
  }
}

.item-modification {
  border-bottom: 1px solid #e4e4e4;

  &:last-child {
    border-bottom: none;
  }
}
