.dropdown-menu-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .dropdown-menu {
    z-index: 10;
  }

  .dropdown-content {
    padding: 10px 5px;
  }

  .dropdown-item {
    cursor: pointer;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .dropdown-trigger {
    cursor: pointer;

    &.icon {
      opacity: 0.7;
    }

    &:hover {
      &.icon {
        opacity: 1;
      }
    }
  }

  .pad-icon {
    padding: 20px;
  }

  &.is-active {
    .dropdown-trigger {
      display: block;

      &.icon {
        opacity: 1;
      }
    }
  }
}
