@import '../../../styles/colors.scss';

.paginated-member-list {
  position: relative;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 2;

  input[type='text'] {
    width: 100%;
  }

  .list__empty {
    border-radius: 3px;
    background-color: #f7f7f7;
    font-family: Mukta;
    font-size: 16px;
    text-align: center;
    color: #7f7579;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  input[type='checkbox'] + label {
    position: relative;
    top: -1px;
    font-size: 13px;
    margin-left: 3px;
  }

  .pagination-wrapper {
    width: 900px;
    margin: 0 auto;
  }

  .pagination {
    margin-top: 30px;
    justify-content: center !important;

    li {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }

      a {
        font-weight: bold;
        padding: 10px 20px;
        border-radius: 5px;
        box-shadow: 0 2px 0 0 rgba(62, 23, 23, 0.05);
        background-color: $rowColor;
        border: solid 1px rgba(81, 30, 30, 0.2);
      }

      &.disabled {
        a {
          color: rgba(141, 141, 141, 0.2);
        }
      }
    }

    .active {
      a {
        color: #ffffff;
        background-color: $accumboBrandMainColor;
      }
    }
  }

  .member-list-headings {
    font-size: 12px;
    font-family: 'IsidoraSans-Bold', sans-serif;
    text-transform: uppercase;
    color: #a69c9c;
    padding: 0 30px;

    .column {
      padding: 0;
    }
  }

  .member-list-row {
    cursor: pointer;
    background-color: $rowColor;
    padding: 10px 30px;
    margin-bottom: 2px !important;
    color: #242423;
    font-family: 'IsidoraSans-SemiBold';

    .column {
      padding: 0;
    }

    &:hover {
      background-color: $rowHoverColor;
    }
  }

  .total-result-count {
    font-family: Mukta, sans-serif;
    font-size: 14px;
    font-style: italic;
  }
}
