.automatic-message-container {
  border-top: 2px solid #f9efc4;
  text-align: center;
  padding: 0 20px;
  margin-top: 30px;

  & > span {
    cursor: pointer;
    display: inline-block;
    font-family: Mukta, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.53;
    color: #242122;
    position: relative;
    top: -19px;
    background-color: #f9efc4;
    border-radius: 17px;
    padding: 6px 18px;
  }
}
