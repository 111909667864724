@import '../../../styles/colors.scss';

.medication-table {
  font-family: Mukta;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
}

.medication-table-column-headers {
  font-family: 'IsidoraSans-SemiBold';
  font-size: 15px;
  background-color: #d4d4d4;
  color: #242423;
  margin-bottom: 0 !important;

  & > div {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.medication-table__empty {
  border-radius: 5px;
  background-color: #f7f7f7;
  font-family: Mukta;
  font-size: 16px;
  text-align: center;
  color: #7f7579;
  padding-top: 14px;
  padding-bottom: 14px;
}

.previous-medication {
  background-color: $medicationPreviousBackgroundColor;
}
.unsigned-medication {
  background-color: $medicationUnsignedBackgroundColor;
}
