@import '../../styles/breakpoints.scss';

.icons__collapsed {
  .icons {
    margin-left: -25px;
    box-shadow: -1px 0px 1px 0px rgba(0, 0, 0, 0.3);

    .event-count,
    .caregiver-abbr,
    .icon.snooze {
      display: none;
    }
  }
}

.errand-item .dropdown-trigger {
  display: none;
}

.errand-item .dropdown-menu-actions {
  &.is-active {
    .icon {
      border-radius: 50%;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
    }

    .dropdown-trigger {
      display: block;
    }
  }

  .icon:hover {
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
  }
}

.errand-item:hover {
  .icons__collapsed .icons {
    margin-left: 10px;
    box-shadow: none;

    .event-count,
    .caregiver-abbr,
    .icon.snooze {
      display: inline;
    }
  }

  .dropdown-trigger {
    display: block;
  }
}

.errand-icon-container {
  position: absolute;
  width: 100%;
  height: 40px;
}

.count-margin-fix {
  margin-left: -1px !important;
}

.errand-actions {
  position: absolute;
  left: -50px;
}
