.decision-support-table {
  font-family: Mukta;
  border: 1px solid #d4d4d4;
  border-radius: 5px;

  &:last-child {
    margin-bottom: 0;
  }
}

.decision-support-table-column-headers {
  font-family: 'IsidoraSans-SemiBold';
  font-size: 15px;
  background-color: #d4d4d4;
  color: #242423;
  margin-bottom: 0 !important;

  & > div {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .offset {
    padding-left: 53px;
  }
}
