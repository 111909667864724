@import '../RowTypes.scss';

.row-container {
  &:last-child {
    .table-row {
      border-bottom: 0;
    }
  }
}

.margin-adjust {
  margin-left: 26px;
}

.generated-response {
  h4 {
    font-size: 16px;
    font-weight: 500;
  }

  p {
    font-size: 18px;
    color: #242423;
  }
}
