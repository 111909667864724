.checkup-timeline-wrapper {
  @media (min-width: 1920px) {
    display: flex;
    justify-content: space-between;
  }
}

.checkup-timeline {
  --near: 30px;
  --far: 120px;
  padding-left: 5px;

  .reminder-control {
    margin-bottom: 8px;

    input[type='checkbox'] + label {
      font-size: 15px;
      margin-left: 3px;
      user-select: none;
    }
  }

  .checkup {
    position: relative;
    border-left: 2px solid #cccccc;
    min-height: var(--far);
    max-height: 200px;
    padding-left: 1em;
    padding-bottom: 0.5em;

    &.near {
      min-height: var(--near);
    }

    &.new {
      .checkup-row-wrapper {
        animation: enter-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) backwards;
      }
    }

    &::before {
      position: absolute;
      content: '';
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #720034;
      left: -13px;
      top: -2px;
      border: 7px solid #ffffff;
    }

    &:last-child {
      border-color: transparent;
    }

    .caregiver-type {
      font-weight: bold;
      color: #720034;
    }

    &.nurse-checkup {
      .caregiver-type {
        color: #344973;
      }

      &::before {
        background-color: #344973;
      }
    }

    &.cancelled {
      .checkup-row-wrapper {
        opacity: 0.5;
        text-decoration: line-through;
      }
    }

    &.completed {
      opacity: 0.6;
    }

    .checkup-info {
      margin-left: 1em;
      margin-top: 0.5em;
      color: #ff6824;
      max-width: 64ch;
    }

    .checkup-row-wrapper {
      display: flex;
      min-width: 30em;
      margin-right: 0.5em;
      justify-content: space-between;
      align-items: center;
      padding: 7px 12px 7px 14px;
      border-radius: 5px;
      border: solid 1px #d4d4d4;
      font-size: 18px;

      .text-button {
        font-size: 15px;
      }

      .info {
        min-width: 27px;
        height: 27px;
      }
    }

    .checkup-date {
      color: #656564;
      margin-bottom: 4px;
    }
  }
}

.treatment-year-overview-wrapper {
  padding: 20px 49px 20px 25px;
  border-radius: 8px;
  background-color: #f5f5f5;
  align-self: flex-start;
  margin-top: -4em;

  @media (max-width: 1919px) {
    margin-top: 2em;
  }
}

.treatment-year-overview {
  margin-left: 1em;
  font-size: 18px;

  div:first-child {
    margin-bottom: 1em;
  }

  @media (max-width: 1919px) {
    display: flex;

    div:first-child {
      margin-right: 2em;
      margin-bottom: 0;
    }
  }

  h2,
  h3 {
    font-weight: 600;
  }

  ul {
    list-style: circle;
    padding-left: 1em;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@keyframes enter-left {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}
