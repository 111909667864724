.dashboard-container {
  margin: 0 auto;
  margin-bottom: 15px;
  font-family: Mukta, sans-serif;

  h1 {
    font-family: 'IsidoraSans-Bold', sans-serif;
    font-size: 26px;
    color: #282827;
    margin-bottom: 7px;
  }

  .patient-count {
    color: #948c90;
    font-size: 18px;

    strong {
      font-weight: 500;
      color: #282827;
    }
  }

  .metric-value {
    font-family: 'IsidoraSans-SemiBold', sans-serif;
    font-size: 50px;
    color: #2e2e2c;
  }

  .metric-description {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.33;
    color: #7f7579;
  }

  select {
    display: inline-block;
  }

  .loading-container {
    position: relative;
    margin-right: 10px;
    top: 4px;
  }
}

.dashboard-wrapper {
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(62, 23, 23, 0.05);
  border: solid 1px rgba(81, 30, 30, 0.2);
  background-color: #ffffff;
  padding: 20px 30px;

  .metric-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
  }
}
