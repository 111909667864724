@import '../../../styles/colors.scss';

.system-message-wrapper {
  border-top: 2px solid #fff7cc;
  text-align: center;
  padding: 0 20px;
  margin-top: 30px;

  .system-message-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-family: Mukta, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.53;
    position: relative;
    top: -19px;
    border: 2px solid #fff7cc;
    background-color: #fff7cc;
    border-radius: 17px;
    padding: 6px 10px 6px 15px;

    &.expanded {
      background-color: #ffffff;
      border-color: #d9d9d9;
    }

    .system-message__content blockquote {
      max-width: 48ch;
    }
  }

  .system-message {
    text-align: left;
  }

  .payment-badge {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-left: 10px;

    &.payment-badge__created {
      background-color: $status-created-color;
    }
    &.payment-badge__pending {
      background-color: $status-pending-color;
      animation: 2s infinite pulse;
    }
    &.payment-badge__exemption {
      background-color: $status-exemption-color;
    }
    &.payment-badge__complete {
      background-color: $status-complete-color;
    }
    &.payment-badge__failed {
      background-color: $status-failed-color;
    }
    &.payment-badge__error {
      background-color: $status-failed-color;
    }
    &.payment-badge__cancelled {
      background-color: $status-failed-color;
    }
  }

  .chevron {
    &:after {
      content: '';
      display: inline-block;
      position: relative;
      width: 12px;
      height: 8px;
      background-image: url('../../../assets/icons/chevron.svg');
      background-repeat: no-repeat;
      margin-left: 5px;
      transition: transform 0.2s ease;
    }
  }
}
