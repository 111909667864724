@import '../../../styles/colors.scss';

.chat-header-container {
  position: relative;
}

.chat-header {
  position: relative;
  display: flex;
  color: #242423;
  border-bottom: 1px solid rgba(81, 30, 30, 0.2);
  padding-bottom: 12px;
  padding-top: 9px;
  padding-left: 10px;
  margin-bottom: 0 !important;

  small {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #51494d;
  }

  .member-source {
    display: inline-block;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 2px 6px;
    margin-left: 10px;

    &.EuroAccident {
      background-color: $euroAccident;
    }

    &.MinDoktor {
      background-color: $minDoktor;
    }
  }

  .chat-filter {
    display: inline;
    position: absolute;
    right: 35px;
    bottom: 10px;

    .notice {
      transform: translateY(2px);
    }
  }
}

.patient-stats,
.patient-info {
  font-size: 17px;

  strong {
    font-weight: 600;
  }
}

.patient-info {
  padding-top: 0;
  flex: none;
}

.patient-stats {
  margin-top: 0.5rem;
}

.patient-stats__height {
  flex: none;
  width: 20%;
}

.patient-stats__weight {
  flex: none;
  width: 19%;
}

.patient-image {
  flex: none;
  padding-right: 5px;

  img {
    cursor: pointer;
    width: 42px;
    height: 42px;
    border-radius: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }

  .profile-image__fallback {
    display: inline-block;
    width: 42px;
    height: 42px;
    background-color: #d8d8d8;
    color: #242423;
    border-radius: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    font-weight: 600;
  }
}

.chat-filter-popover {
  font-family: IsidoraSans-SemiBold, sans-serif;
  width: fit-content;
  padding: 20px;
  position: fixed;
  z-index: 1;
  background-color: #ffffff;
  border: solid 1px #dcd2d2;
  box-shadow: 0 0 0 4px rgba(62, 23, 23, 0.05);
  border-radius: 10px;
  transition: top 0.2s ease;
  right: 20px;
  top: 70px;
  opacity: 1;

  &.popover-hidden {
    opacity: 0;
    pointer-events: none;
    top: 60px;
  }

  label {
    display: block;
    user-select: none;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    input {
      margin-right: 5px;
    }
  }
}
