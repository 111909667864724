@import '../../../styles/colors.scss';

.chat-message {
  margin-bottom: 1rem;
  width: 80%;
  max-width: 800px;
  scroll-margin-top: 10px;

  a {
    text-decoration: underline;
    word-break: break-all;
  }
}

.chat-message__content {
  padding: 1em;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  flex: none;
  overflow-wrap: break-word;
}

.chat-message__thumbnail {
  cursor: pointer;
  min-height: 112px;
  margin-bottom: 5px;

  img {
    width: 150px;
    height: 100%;
    border-radius: 4px;
  }
}

.chat-message__timestamp {
  font-size: 14px;
  font-weight: 500;
  color: #808080;
  padding-top: 5px;
}

.message-incoming {
  margin-right: auto;
  margin-left: 20px;

  .chat-message__content {
    color: #242423;
    border-radius: 20px 20px 20px 0;
    border: solid 2px #d9d9d9;
    max-width: 88%;
  }

  .columns {
    margin-bottom: 0;
  }
}

.message-outgoing {
  margin-left: auto;
  margin-right: 20px;

  .chat-message__content {
    border-radius: 20px 20px 0 20px;

    &.message-draft {
      color: #152032;
      border: dashed 2px #dddddd;
      background-color: #f5f5f5;
    }
  }

  .chat-message__timestamp {
    text-align: right;
  }

  .caregiver-name {
    font-weight: bold;
    color: #242423;
    margin-right: 5px;
  }

  .message-actions {
    text-align: right;

    time {
      display: inline-block;
    }

    > span {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    > span,
    > time {
      font-weight: 500;
      color: #808080;
      font-size: 14px;
      margin-right: 5px;
    }
  }
}

.chat-avatar {
  margin-top: auto;
  flex: none;
  width: 12%;
}

.chat-avatar__placeholder {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: #d8d8d8;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #242423;
  background-size: contain;
}

.delivered-icon {
  margin-right: 5px;
}

.flexrow {
  display: flex;
}

.flex1 {
  flex: 1 1 auto;
}

.flex0 {
  flex: 0 0 auto;
  max-width: 100%;
}

.author-type__doctor {
  .chat-message__content {
    color: $accumboBrandMainColor;
    background-color: $accumboCaregiverChatBackground;
  }
}

.author-type__nurse {
  .chat-message__content {
    color: $accumboNurseChatTextColor;
    background-color: $accumboNurseChatBackground;
  }
}

.author-type__dietician {
  .chat-message__content {
    color: $accumboDieticianChatTextColor;
    background-color: $accumboDieticianChatBackground;
  }
}

.chat-message-skeleton {
  span {
    background-color: #e9e9e9;
    color: #e9e9e9 !important;
    animation: 2s infinite pulse;

    &:not(.chat-avatar__placeholder) {
      border-radius: 5px;
    }
  }

  .chat-message__content {
    background-color: #d8d8d8;
    color: #d8d8d8 !important;
    animation: 2s infinite pulse;
  }
}

.draft-countdown {
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}

@keyframes pulse {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.9;
  }
}
