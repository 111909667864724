.onboarding {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    font-size: 18px;
    padding-left: 22px;
    padding-bottom: 5px;
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 6px;
      width: 14px;
      height: 14px;
      background-color: #d8d8d8;
      border-radius: 50%;
    }

    &.done {
      color: #3d996b;

      &:before {
        background-image: url('../../../assets/icons/action-done.svg');
      }
    }

    &.incomplete {
      color: #d8384a;

      &:before {
        background-color: #d8384a;
      }
    }

    &.partial {
      color: #d87f38;

      &:before {
        background-color: #d87f38;
      }
    }
  }
}
