.error-wrapper {
  background-image: url('../../assets/profile-failed.svg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 20px;
  height: 300px;
  font-family: Mukta, sans-serif;
  font-size: 18px;
  width: 90%;
  max-width: 580px;
  margin: 0 auto;
  margin-top: 200px;
  text-align: center;
  padding-top: 200px;
}
