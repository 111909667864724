.loader-button-image {
  margin: 0 0 5px 8px;
  height: 22px;
  animation: spin 1s linear infinite;
  max-width: none;

  &.large {
    height: 26px;
  }
}

.loader-button-container {
  margin-bottom: -0.75rem !important;
}
