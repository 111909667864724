.modal-content,
.modal-card {
  overflow: unset !important;
}

.modal-background {
  opacity: 0.3;
  background-color: #4d3939;

  &.opaque {
    opacity: 1;
    background-color: #720034;
  }
}

.modal-content {
  font-family: Mukta, sans-serif;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgba(62, 23, 23, 0.25);
  pointer-events: all;

  h3 {
    margin-bottom: 0;
  }

  h4 {
    font-family: Mukta;
    font-size: 16px;
    color: #7f7579;
    margin-bottom: 5px;
  }

  &.modal-content__large {
    width: 65%;

    .modal-content__children {
      height: 75vh;
      max-height: 75vh;
      overflow-y: auto;

      &.modal-content-max-height {
        max-height: 75vh;
        height: auto;
      }
    }
  }

  &.modal-content__slim {
    width: 40%;
    max-width: 600px;

    .modal-content__children {
      height: 75vh;
      max-height: 75vh;
      overflow-y: auto;
      max-height: calc(920px - 30px - 30px - 78px);
    }
  }

  &.modal-content__wide {
    width: 56%;
    max-width: 1000px;

    .modal-content__children {
      height: 75vh;
      overflow-y: auto;
      max-height: 900px;
    }

    &.has-action {
      .modal-content__children {
        max-height: calc(900px - 78px);
      }
    }
  }

  &.modal-content__medium {
    width: 56%;

    .modal-content__children {
      height: 56vh;
      max-height: 56vh;
    }
  }

  &.modal-content__medium-small {
    width: 45%;

    .modal-content__children {
      height: 75vh;
      max-height: 75vh;
      overflow-y: auto;
    }
  }

  &.modal-content__small {
    width: 27%;
    max-width: 400px;
  }

  &.modal-content__x-small {
    width: 25%;
    max-width: 300px;

    .modal-content__children {
      min-height: 380px;
    }
  }
}

.modal-close-button {
  user-select: none;
  border: none;
  cursor: pointer;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  outline: none;
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: auto;
  background-image: url('../../assets/icons/cross.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.modal-content__header {
  padding: 0 30px;
  margin-bottom: 0 !important;

  h3 {
    font-family: IsidoraSans-SemiBold;
    font-size: 20px;
    color: #343434;
  }
}

.modal-content__children {
  padding: 30px;
  height: 100%;
  background-color: #ffffff;
  max-height: 50vh;
  overflow-y: auto;

  & > div {
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.modal-content__footer {
  background-color: #f1f1f1;
  padding: 20px;

  .column {
    padding-bottom: 10px;
  }

  .columns:last-child {
    margin-bottom: -0.75rem;
  }

  button {
    .column {
      padding-bottom: 0;
    }
  }

  input[type='checkbox'] + label {
    font-size: 16px;
  }

  .styled-checkbox {
    &:checked + label:after {
      left: 3px;
      top: 4px;
    }
  }
}

.modal-action {
  text-align: right;
  padding-right: 0;
}

.modal-top-right {
  position: fixed;
  right: 18px;
  top: 72px;
}

.handle {
  cursor: move;
  height: 30px;
}

.pointer-events-none {
  pointer-events: none;
}

.auxiliary-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  div {
    cursor: pointer;
  }
}

.back-button {
  position: relative;
  top: 1px;
  cursor: pointer;
  font-family: Mukta, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #720034;
  background-image: url('../../assets/icons/back-chevron.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 20px;
  margin-right: 10px;
  flex-grow: 0;

  span {
    position: relative;
    top: 2px;
  }
}
