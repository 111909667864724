.color-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;

  & + span {
    margin-right: 10px;
  }

  &.morning {
    background-color: rgb(234, 188, 12);
  }

  &.day {
    background-color: rgb(54, 135, 105);
  }

  &.evening {
    background-color: rgb(114, 5, 58);
  }

  &.night {
    background-color: #000000;
  }
}

.measurement-times-legend {
  text-align: center;
  font-family: Mukta, sans-serif;
}
