.editable-text-field-container {
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(62, 23, 23, 0.05);
  background-color: #ffffff;
  border: solid 1px rgba(81, 30, 30, 0.2);
  padding: 20px;
  margin-bottom: 20px;

  textarea {
    width: 100%;
    min-height: 230px;
    padding: 10px;
    border-radius: 3px;
    border: solid 1px #cfcfcf;
    font-family: Mukta;
    font-size: 16px;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.editable-text-field-wrapper {
  padding-left: 0;
}

.editable-text-field-actions {
  text-align: right;
  margin-top: 20px;
}

.history-button {
  cursor: pointer;
  font-family: Mukta, sans-serif;

  &:hover {
    font-weight: bold;
  }
}
