@import '../../../styles/colors.scss';

.center-preview-loader {
  width: 30px;
  margin: 0 auto;
}

.member-files {
  font-family: Mukta, sans-serif;
}

.file-row {
  cursor: pointer;
  border-bottom: 1px solid #d9d9d9;
  background-color: #ffffff;

  &.document {
    padding: 15px 0;

    & > .columns {
      min-height: 100px;
    }
  }

  &.image {
    margin-bottom: 15px;

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
    }
  }

  &:hover {
    background-color: #f4f3f3;
  }
}

.document-action {
  cursor: pointer;
  font-family: 'IsidoraSans-Bold', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #72053a;

  &:last-child {
    margin-left: 10px;
  }
}

.file-upload-dialog {
  form {
    margin-top: 10px;

    .column {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  textarea {
    border: none;
    min-height: 70px;
    width: 100%;
    font-size: 16px;
    color: #242423;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }

  input[type='file'],
  input[type='submit'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
      cursor: pointer;

      &:last-child {
        margin-left: 15px;
      }
    }
  }

  .input-container {
    padding-left: 0;
    display: flex;
    align-items: center;
  }

  input[type='file'] + label {
    display: inline-block;
    margin-bottom: 30px;
    background-color: $accumboBrandMainColor;
    border-radius: 3px;
    color: #ffffff;
    font-size: 15px;
    font-weight: normal;
    box-shadow: 0 1px 2px 0 #c8bebe;
    height: auto;
    padding: 9px 15px;
  }

  .radio {
    display: block;
    font-family: Mukta;
    font-size: 18px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    & + .radio {
      margin-left: 0;
    }

    input[type='radio'] {
      margin-right: 7px;
    }
  }

  .selected-file {
    border-radius: 3px;
    border: solid 1px #cfcfcf;
    padding: 10px;
  }

  .file-select-container {
    margin: 30px 0;
  }

  .input-container {
    padding-right: 20px;

    .button {
      margin-left: auto;
    }
  }
}
