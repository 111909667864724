@import '../../styles/colors.scss';

.edit-profile-image {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #bbbbbb;
  min-width: 30px !important;
  height: 30px !important;
  border-radius: 15px;
  border: 2px solid #ffffff;
}

.cropper-wrapper {
  position: relative;
  width: 100%;
  height: calc(100% - 4rem);
  margin-bottom: 10px;

  .button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    width: 40px;
    box-shadow: none;
    font-size: 1.5em;

    &:last-of-type {
      top: 60px;
    }
  }
}

.profile-image-upload__drop-target {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 10px;
  border: 2px dashed $accumboBrandMainColor;

  &.is-dragging {
    opacity: 0.5;
    border-width: 4px;
  }
}

.profile-picture-crop-preview {
  display: flex;
  justify-content: center;

  img {
    max-width: 300px;
    border-radius: 50%;
  }
}

.app-preview {
  font-family: 'IsidoraSans-SemiBold', sans-serif;

  .chat-wrapper {
    display: flex;
    margin-bottom: 5px;

    .app-profile-image {
      width: 10%;
      display: flex;
      align-items: flex-end;
      margin-right: 10px;

      img {
        max-width: 37px;
        border-radius: 50%;
      }
    }
    .chat-content {
      border-radius: 20px 20px 20px 0;
      padding: 0.6em;
      font-size: 16px;
      font-weight: 500;

      &.caregiver-role__doctor {
        color: $accumboBrandMainColor;
        background-color: $accumboCaregiverChatBackground;
      }

      &.caregiver-role__nurse {
        color: $accumboNurseChatTextColor;
        background-color: $accumboNurseChatBackground;
      }
    }
  }

  .chat-meta {
    font-size: 14px;
  }
}

.file-select-container {
  display: flex;
  align-items: center;

  span:last-child {
    flex: 1;
    text-align: right;
  }
}

video {
  border-radius: 10px;
  transform: scaleX(-1); // Mirror the video. It's what most people are used to when using a selfie camera.
}
