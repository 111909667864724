.checkup-booking-selector {
  margin-top: 50px;

  input[type='checkbox'] {
    margin-right: 10px;

    + label {
      font-size: 16px;
    }
  }

  .date-input {
    padding: 9px 15px 7px;
    border-radius: 5px;
    border: solid 2px #dadada;
    margin-left: 25px;
    margin-right: 10px;
    background-image: url('../../../assets/icons/card-status-scheduled-checkup-grey.svg');
    background-repeat: no-repeat;
    background-position: 90% 50%;
    padding-right: 4em;
  }

  .date-input__placeholder {
    opacity: 0.7;
    cursor: pointer;
  }
}

.checkup-type-heading {
  font-family: Mukta, sans-serif;
  font-size: 16px;
  color: #7f7579;
  margin: 5px 0;
  margin-left: 7px;
}

.reminder-selection .date-input {
  margin-left: 10px;
  margin-top: 10px;
}
