.chat-search-container {
  width: 100%;

  input {
    width: 100%;
    background-image: url('../../../assets/icons/search.svg');
    background-repeat: no-repeat;
    background-position: 10px 50%;
    padding-left: 35px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    margin-bottom: 1px;
  }

  .author-name {
    font-weight: bold;
  }

  .chat-search-results {
    width: 100%;
  }

  .dropdown {
    position: absolute;
    width: 80%;
    z-index: 3;
    left: 10px;

    .dropdown-content {
      padding: 10px;
      max-height: 80vh;
      overflow-y: auto;
    }

    .dropdown-item.keyboard-selected {
      color: #72053a;
      background-color: rgba(254, 228, 237, 0.5);
    }
  }

  .dropdown-menu-test {
    width: 100%;
  }
}
