input[type='text'].input-large {
  border-radius: 27px;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 95%;
  max-width: 320px;
  height: 55px;
  margin-bottom: 10px;
  outline: none;
  padding-left: 25px;
  font-size: 17px;
  color: #242423;
}
