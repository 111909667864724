@import '../RowTypes.scss';

.row-container.checkbox-row {
  &:last-child {
    .table-row {
      border-bottom: 0;
    }
  }

  .styled-checkbox + label:before {
    margin-right: 15px;
  }

  input[type='text'] {
    width: 100%;
    max-width: 500px;
  }
}
