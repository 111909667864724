.final-note-modal-container {
  padding-bottom: 30px;

  input[type='text'],
  textarea {
    width: 100%;
  }

  .columns {
    margin-bottom: 30px;
  }

  .treatment-reason-text {
    min-height: 200px;
  }

  .select select {
    height: 45px;
  }
}

.checkup-info-container {
  display: inline-block;
  min-width: 50%;
}
