.measurement-periods-container {
  position: relative;
}

.measurement-periods {
  margin-top: 20px;

  &.loading {
    opacity: 0.5;
  }
}

.measurement-periods-loader {
  position: absolute;
  width: 59px;
  height: 59px;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
}
