.patient-chat {
  background-color: #ffffff;
  position: relative;
  height: 100vh;
  border-left: 1px solid rgba(81, 30, 30, 0.2);
  font-family: 'Mukta';
}

.chat-messages-container {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 10px;
  max-height: calc(
    100vh - 300px - 63px - 26px - 39px
  ); // Full height minus chat header height minus chat footer height minus padding minus searchbar
  min-height: calc(100vh - 300px - 63px - 26px - 39px);
  overflow-y: auto;
}

.polling-error {
  position: relative;
  bottom: 25px;
  margin-left: 10px;
}

.pusher {
  flex: 1;
}

@media screen and (max-width: 769px) {
  .chat-messages-container {
    max-height: calc(100vh - 340px);
    min-height: calc(100vh - 340px);
  }
}
