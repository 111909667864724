.sign-container {
  margin-top: 40px;
}

.checkup-explanation {
  font-family: Mukta, sans-serif;
  margin-bottom: 10px;
  color: #50494d;
}

.create-note-button {
  background-image: url('../../assets/icons/create-daynote.svg');
  background-repeat: no-repeat;
  background-position: 10px;
  padding-left: 40px;
}

.note-container {
  margin-bottom: 30px;

  textarea {
    width: 100%;
    height: 400px;
    border-radius: 3px;
    border: solid 1px #cfcfcf;
    padding: 10px;
  }
}

.jira-description {
  margin-top: 10px;

  textarea {
    width: 100%;
  }
}

.included-tests-container {
  height: 284px;
  overflow: auto;
  font-size: 18px;
  font-weight: 500;
  padding: 20px 10px 0 10px;
  overscroll-behavior: contain;

  &.stack > * + * {
    margin-top: 10px;
  }

  label {
    margin-left: 10px;
  }
}

.referral-recipient {
  font-size: 12px;
  font-style: italic;
  opacity: 0.7;
  text-align: right;
  position: relative;
  top: 25px;
  right: -10px;
}

.expandable-row {
  cursor: pointer;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  padding: 10px;

  &.is-new {
    border-left: 4px solid #ffac59;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:hover {
    background-color: #fcfcfc;
  }

  img {
    transition: transform 0.2s ease;
  }

  .referral-creator {
    width: 32%;
  }

  .expand-indicator {
    width: 10px;
  }
}

.active-caregiver {
  display: inline-block;
  background-color: #3d996b;
  color: white;
  padding: 1px 6px;
  margin-left: 7px;
  border-radius: 4px;
  font-size: 0.8em;
  position: relative;
}
