.anamnesis-chapters {
  & > li {
    max-width: 800px;
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(62, 23, 23, 0.05);
    background-color: #ffffff;
    border: solid 1px rgba(81, 30, 30, 0.2);
    margin-bottom: 15px;
    margin-right: 20px;
  }

  h2 {
    font-family: IsidoraSans-SemiBold;
    font-size: 18px;
    color: #242423;
  }

  .hidden {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
  }
}

.member-info-container {
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(62, 23, 23, 0.05);
  background-color: #ffffff;
  border: solid 1px rgba(81, 30, 30, 0.2);
  padding: 20px;

  h3 {
    font-family: IsidoraSans-Bold;
    font-size: 18px;
  }

  p {
    font-family: Mukta;
    font-size: 16px;
  }
}
