@import '../../../styles/colors.scss';

.chat-footer {
  border-top: 1px solid rgba(81, 30, 30, 0.2);
  position: absolute;
  bottom: 12px;
  width: 100%;
  background: #fff;
  height: 300px;

  form {
    margin-top: 10px;

    .column {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  textarea {
    border: none;
    min-height: 205px;
    width: 100%;
    font-size: 16px;
    color: #242423;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }

  input[type='file'],
  input[type='submit'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
      cursor: pointer;

      &:last-child {
        margin-left: 15px;
      }
    }
  }

  .input-container {
    padding-left: 0;
    display: flex;
    align-items: center;
  }

  input[type='file'] + label {
    background-color: $accumboBrandMainColor;
    border-radius: 3px;
    color: #ffffff;
    font-size: 15px;
    font-weight: normal;
    box-shadow: 0 1px 2px 0 #c8bebe;
    height: auto;
    padding: 9px 15px;
    position: relative;
    top: 10px;
  }

  input[type='submit'] + label {
    background-image: url('../../../assets/send-message-icon.svg');
    display: inline-block;
    width: 24px;
    height: 24px;
  }

  .file-select {
    cursor: pointer;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('../../../assets/icons/attachment-icon.svg');
    margin-top: 10px;
    margin-right: 15px;
  }

  .radio {
    display: block;
    font-family: Mukta;
    font-size: 18px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    & + .radio {
      margin-left: 0;
    }

    input[type='radio'] {
      margin-right: 7px;
    }
  }

  .selected-file {
    border-radius: 3px;
    border: solid 1px #cfcfcf;
    padding: 10px;
  }

  .file-select-container {
    margin: 30px 0;
  }

  .input-container {
    padding-right: 20px;

    .button {
      margin-left: auto;
    }
  }
}

.chat-footer-auxiliary-actions {
  background-color: #fbfbfb;
  padding: 13px 20px;
  font-family: 'IsidoraSans-SemiBold', sans-serif;
  color: #7f7579;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;

  .column {
    padding-left: 0;
    padding-right: 0;
  }

  .footer-action {
    max-width: 175px;
  }
}

@media screen and (max-width: 1650px) {
  .chat-footer-auxiliary-actions .populate-canned-message {
    font-size: 11px;
  }
}

.diagnosis-code {
  input {
    width: 300px;
  }
}

.expand-chat {
  cursor: pointer;
}

.chat-modal-content {
  height: 100%;

  textarea {
    height: 100%;
    width: 100%;
    border-radius: 3px;
    border: solid 1px #cfcfcf;
    padding-top: 1em;
    font-size: 18px;
    line-height: 1.33;
  }
}

.datepicker-button {
  cursor: pointer;
}

.scheduled-checkup-container {
  margin-top: 20px;

  select {
    height: 40px;
  }
}

.populate-canned-message {
  margin-left: 10px;
}
