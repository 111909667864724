@import '../RowTypes.scss';
@import '../../../../../styles/colors.scss';

.medication-status {
  font-family: Mukta;
  font-size: 16px;
  line-height: 1.5;
  color: #656564;
  padding-left: 10px;
  margin-bottom: 10px;
}

.medication-status-badge {
  background-color: #eb495b;
  border-radius: 3px;
  font-family: Mukta, sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  padding: 1px 8px;
  text-transform: uppercase;
  margin-right: 10px;
}
