.anamnesis-chapter-title {
  display: flex;
  cursor: pointer;
  padding: 20px 30px;
}

.anamnesis-chapter-title__text {
  width: 99%;

  h2 {
    margin-bottom: 0 !important;
  }
}

.anamnesis-chapter-title__icon {
  img {
    transition: transform 0.2s ease;
  }
}

.anamnesis-chapter-content {
  max-height: 5000px; // Todo: Bättre värde här
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  font-family: Mukta;
  font-size: 16px;
  font-weight: 600;
  padding: 0 30px;

  li {
    margin-bottom: 25px;
  }
}

.anamnesis-chapter-content__question {
  color: #50494d;
  opacity: 0.8;
  font-weight: normal;

  &.mandatory {
    opacity: 1;
    border-radius: 5px;
    background-color: #d8384a;
    color: #ffffff;
    padding: 3px 5px;

    &:after {
      display: inline-block;
      content: '*';
      color: #ffffff;
    }
  }

  &.highlighted {
    color: #242423;
    border-radius: 2px;
    padding: 2px;
    background-color: #ffac59;
  }
}

.anamnesis-chapter-content__answer {
  margin-top: 5px;
}
