.anamnesis-update-container {
  position: relative;
  font-family: Mukta, sans-serif;
  font-size: 18px;
  width: 250px;
  max-width: 250px;
  min-height: 210px;
  height: 210px;
  padding: 10px 20px;
  border-radius: 5px;
  border: solid 1px #d4d4d4;
  background-color: #f0f0f0;

  margin-right: 20px;
  margin-bottom: 5px;
  margin-top: 5px;

  &:last-child {
    margin-right: 0;
  }

  .anamnesis-update-date {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .anamnesis-update-content-preview {
    margin-bottom: 5px;
  }

  span.text-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-weight: 800;
    color: #720034;
  }

  &.new {
    background-color: #ffac59;
  }
}

.anamnesis-update-modal {
  font-size: 16px;
  white-space: normal;

  .additional-question {
    opacity: 0.8;
    color: #50494d;
    margin-bottom: 5px;
  }

  .additional-answer {
    font-weight: 600;
    color: #242423;
    margin-bottom: 30px;
  }

  .anamnesis-update {
    border-radius: 5px;
    border: solid 1px #d4d4d4;
    margin-bottom: 15px;

    .new-answer {
      border-right: 1px solid #d4d4d4;
    }

    .timestamp {
      font-size: 14px;
      font-weight: 600;
      color: #656564;
      margin-bottom: 5px;
    }
  }

  .anamnesis-question {
    padding: 10px 15px;
    opacity: 0.8;
    color: #242424;
    background-color: #f2f2f2;
    border-bottom: 1px solid #d4d4d4;
  }

  .anamnesis-answer {
    font-weight: 600;
    color: #242423;
  }

  .num-changed-answers {
    font-size: 18px;
    font-weight: 500;
    color: #242423;
    margin-bottom: 15px;
  }
}
