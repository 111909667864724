.tabbed-modal {
  .handle {
    background-color: #f1f1f1;
    height: 20px;
  }
}

.tabbed-modal-content {
  width: 870px;
}

.modal-tabs-content {
  padding: 30px;
  min-height: 650px;
  max-height: 650px;
  overflow-y: auto;
}

.modal-tabs {
  position: relative;
  background-color: #f1f1f1;
  padding: 0 20px;
  border-bottom: 1px solid #cccccc;
}

.modal-close-button-container {
  position: absolute;
  right: 20px;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  font-family: Mukta, sans-serif;

  &__tab-list {
    padding: 0;
  }

  &__tab {
    user-select: none;
    display: inline-block;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 12px 15px;
    margin-right: 5px;
    cursor: pointer;
    border-color: #ccc;
    color: #656564;

    &--selected {
      border-bottom: 1px solid #fff;
      color: #343434;
    }

    &--disabled {
      color: lightgray;
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
